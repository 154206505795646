import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppStateService } from './app-state.service';
import { AppConfig, applicationConfiguration } from '../config/app.config';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RootService {
  showServicecall: boolean = false;
  expiredDate: any;
  protected server = this.appConfig.apiUrl + 'api/v1/';
  protected serve = this.appConfig.apiUrl;
  readonly options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'request-id': 'b997a77ae2b3f30c1bed151c3e42434f',
    }),
  };
  constructor(
    private appStateService: AppStateService,
    private http: HttpClient,private route: ActivatedRoute,
    private cookieService: CookieService,
    public router: Router,
    @Inject(applicationConfiguration) private appConfig: AppConfig
  ) { }

  login(users): Observable<any> {
    try {
      let device_id;
      let deviceid = localStorage.getItem('uniqueId');
      if (deviceid) {
        device_id = deviceid;
      } else {
        var result = '';
        let random;
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < 10; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        random = Date.now() + Math.floor(Math.random() * 100000000000000000000)
        random += Math.floor(Math.random() * 100000000000000000000)
        random += result
        localStorage.setItem('uniqueId', random);
        device_id = random
      }
      let user = {
        "device_id": device_id,
      }
      return this.http.get(`${this.serve}api/v1/get_freesearch_token/`, this.options);
    }
    catch (error) {
    }
  }

  getToken(): Promise<any> {

    try {
      let device_id;
      let deviceid = localStorage.getItem('uniqueId');
      if (deviceid) {
        device_id = deviceid;
      } else {
        var result = '';
        let random;
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < 10; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        random = Date.now() + Math.floor(Math.random() * 100000000000000000000)
        random += Math.floor(Math.random() * 100000000000000000000)
        random += result
        localStorage.setItem('uniqueId', random);
        device_id = random
      }

      let user = {
        "device_id": device_id,
      }


      if (!this.showServicecall) {
        this.showServicecall = true;
        const promise = this.http.get(`${this.serve}api/v1/get_freesearch_token/`, this.options).toPromise()
          .then((response: any) => {
            if (response) {
              this.showServicecall = false;
              this.expiredDate = new Date();
              let Minutes = response.data['expires_in'] * 1000; // convert 2 minutes to milliseconds
              var expirein = (Minutes - (Minutes / 10))
              let expiredate2 = new Date(this.expiredDate.getTime() + expirein);
              this.cookieService.putObject('access_token', response.data['access_token'], { 'expires': expiredate2 });
              this.cookieService.putObject('ref_token', response.data['refresh_token']);
             localStorage.setItem('ref_token', response.data['refresh_token']);
              localStorage.setItem('access_token', response.data['access_token']);
              localStorage.setItem('refreshTokenInProgress', 'false');
            }
            return
          })
        return promise;
      }
    }
    catch (error) {
    }
  }



  async AyncgetToken() {
    let createdEmployee = await this.Refreshtocken();
    if (createdEmployee.success == true) {
      this.showServicecall = false;
      this.expiredDate = new Date();
      let Minutes = createdEmployee.data['expires_in'] * 1000; // convert 2 minutes to milliseconds
      var expirein = (Minutes - (Minutes / 10))
      let expiredate2 = new Date(this.expiredDate.getTime() + expirein);
      this.cookieService.putObject('access_token', createdEmployee.data['access_token'], { 'expires': expiredate2 });
      this.cookieService.putObject('ref_token', createdEmployee.data['refresh_token']);
      localStorage.setItem('ref_token', createdEmployee.data['refresh_token']);
      localStorage.setItem('access_token', createdEmployee.data['access_token']);
      localStorage.setItem('refreshTokenInProgress', 'false');
    }
  }


  async Refreshtocken(): Promise<any> {
    return await this.http.get(`${this.serve}api/v1/get_freesearch_token/`, this.options).toPromise();
  }


  refreshToken(): Promise<any> {
    try {
      let refreshToken = this.cookieService.get("ref_token") ? JSON.parse(this.cookieService.get("ref_token")) : localStorage.getItem('ref_token');
      let data = {}
      if (refreshToken) {
        data = {
          refresh_token: refreshToken
        }
        if (!this.showServicecall) {
          this.showServicecall = true;
          const promise = this.http.get(`${this.serve}api/v1/get_freesearch_token/`, this.options).toPromise()
            .then((response: any) => {
              this.showServicecall = false;
              if (response['success'] === true) {
                this.expiredDate = new Date();
                let Minutes = response.data['expires_in'] * 1000;
                var expirein = (Minutes - (Minutes / 10))
                let expiredate2 = new Date(this.expiredDate.getTime() + expirein);
                this.cookieService.putObject('access_token', response.data['access_token'], { 'expires': expiredate2 });
                this.cookieService.putObject('ref_token', response.data['refresh_token']);
                localStorage.setItem('ref_token', response.data['refresh_token']);
                localStorage.setItem('refreshTokenInProgress', 'false');
              }
              return
            })
          return promise;
        }
      }
    }
    catch (error) {
    }

  }


  refreshToken2() {
    let refreshToken = this.cookieService.get("ref_token") ? JSON.parse(this.cookieService.get("ref_token")) : localStorage.getItem('ref_token');
    let data = {}
    if (refreshToken) {
      data = {
        refresh_token: refreshToken
      }
    }
    if (!this.showServicecall) {
      this.showServicecall = true;
      return this.http.get<any>(`${this.serve}api/v1/get_freesearch_token/`, this.options).pipe(tap((response) => {
        this.showServicecall = false;
        localStorage.setItem('refreshTokenInProgress', 'false');
        this.storeJwtToken(response);

      }));
    }
  }
  returnUrl: any
  async refreshTokennew(url) {

    var data = {
      refresh_token: localStorage.getItem('ref_token')
    }

    let createdEmployee = await this.RefreshNewtocken(data);
    if (createdEmployee.success == true) {
      //  this.storeJwtToken(createdEmployee);
      const time_to_login = Date.now() + createdEmployee.data.expires_in;
      localStorage.setItem('timer', JSON.stringify(time_to_login));
      // localStorage.setItem('loginUserData', JSON.stringify(createdEmployee))
      localStorage.setItem("isLoggedin", 'true');
      this.expiredDate = new Date();
      let Minutes = createdEmployee.data.expires_in * 1000; // convert 2 minutes to milliseconds
      var expirein = (Minutes - (Minutes / 10))
      let expiredate2 = new Date(this.expiredDate.getTime() + expirein);
      // this.cookieService.putObject('access_token', createdEmployee.token['access_token'], { 'expires': expiredate2 });
      // this.cookieService.putObject('ref_token', createdEmployee.token['refresh_token']);
      localStorage.setItem('ref_token', createdEmployee.data.refresh_token);
      localStorage.setItem('acctual_token', createdEmployee.data.access_token);
      localStorage.setItem('refreshTokenInProgress', 'false');
      this.onRefresh();
    }
  }


  RefreshUrl: any = this.serve + 'api/v1/refresh_auth/'
  async RefreshNewtocken(Data: any): Promise<any> {
    return await this.http.post(this.RefreshUrl, Data, { headers: { "Content-Type": "application/json" } }).toPromise();
  }


  private storeJwtToken(response) {
    if (response['success'] === true) {
      this.expiredDate = new Date();
      let Minutes = response.data['expires_in'] * 1000;
      var expirein = (Minutes - (Minutes / 10))
      let expiredate2 = new Date(this.expiredDate.getTime() + expirein);
      // this.cookieService.putObject('access_token', response.data['access_token'], { 'expires': expiredate2 });
      // this.cookieService.putObject('ref_token', response.data['refresh_token']);
      localStorage.setItem('ref_token', response.data['refresh_token']);
      localStorage.setItem('access_token', response.data['access_token']);
      localStorage.setItem('refreshTokenInProgress', 'false');

    }
  }
  onRefresh() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    let currentUrl = this.router.url;
    if (currentUrl.includes('view-vehicle')) {
      this.router.navigateByUrl(currentUrl)
        .then(() => {
          this.router.navigated = false;
          this.router.navigate(['page/view-vehicle'], { queryParams: { isInventory: this.route.snapshot.params.param1 } })
        });
    }
    else if (currentUrl.includes('view-auction')) {
      this.router.navigateByUrl(currentUrl)
        .then(() => {
          this.router.navigated = false;
          this.router.navigate(['page/view-auction'], { queryParams: { isViewMore: this.route.snapshot.params.param1 } })
        });
    }
    else {
      this.router.navigated = false;
      this.router.navigate(['page/profile'], { queryParams: { index: 1 } })
    }
  }
}


import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TataMFLRootComponent } from './tata-mfl-root.component';
import { Routes, RouterModule } from '@angular/router';
import { CustomLocationStrategy } from './custom-location-strategy';
import { LocationStrategy } from '@angular/common';
import { MyHttpInterceptor } from './app/shared/Interceptor/http.interceptor';
import { CookieModule, CookieService } from 'ngx-cookie';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { AppStateService } from './app/shared/services/app-state.service';
import { RootService } from './app/shared/services/root.service';
import { SlickCarouselModule } from 'ngx-slick-carousel';



export function init_app(
  rootService: RootService,
  appStateService: AppStateService,
  cookieService: CookieService,
) {

  let user = localStorage.getItem("userData");
  let userRecord = localStorage.getItem("user");
  let token = cookieService.get("access_token");
  let refreshToken = cookieService.get("ref_token") ? cookieService.get("ref_token") : localStorage.getItem("ref_token");
  if (user && userRecord && refreshToken) {
    //appStateService.isLoggedIn.next(true);
    localStorage.removeItem('cartItems');
    // appStateService.userSubject.next(JSON.parse(user));
    return () => rootService.refreshToken();
  }
  return () => rootService.getToken();
}


const appRoutes: Routes = [
  { path: "", redirectTo: "page", pathMatch: "full" },
  { path: "page", loadChildren: () => import('./app/shared/layout/layout.module').then(m => m.LayoutModule) },
];



@NgModule({
  declarations: [
    TataMFLRootComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ModalModule,
    ToastrModule, SlickCarouselModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({ cookieName: 'XSRF-TOKEN', headerName: 'X-CSRF-TOKEN' }),
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    CookieModule.forRoot(),
    RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' })
    // RouterModule.forRoot(appRoutes)

  ],
  providers: [
    { provide: LocationStrategy, useClass: CustomLocationStrategy },
    {
      provide: APP_INITIALIZER, useFactory: init_app,
      deps: [RootService, AppStateService, CookieService],
      multi: true
    },
    BsModalService,
    { provide: HTTP_INTERCEPTORS, useClass: MyHttpInterceptor, multi: true }
  ],
  bootstrap: [TataMFLRootComponent]
})
export class TataMFLRootModule { }

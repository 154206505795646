import { InjectionToken } from "@angular/core";
import { environment } from "../../../environments/environment";

export const myAppConfig: any = {
  appname: "Tata-MFL",
  titlename: "Tata-MFL",
  apiUrl: environment.apiUrl};


export const applicationConfiguration = new InjectionToken<AppConfig>(
  "app.config",
  { providedIn: "root", factory: () => myAppConfig }
);


export interface AppConfig {
  appname: string;
  titlename: string;
  apiUrl: string;
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, } from 'rxjs';
import { Data } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
 
  loginData;
  constructor() {
   
  }

 

}
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  template: '<router-outlet ></router-outlet>',
})
export class TataMFLRootComponent  implements OnInit , OnDestroy  {

  @HostListener("window:onbeforeunload",["$event"])
    clearLocalStorage(event){
        localStorage.clear();
    }
    
  title = 'tata-MFL';
  constructor(private router:Router){}
 


  ngOnDestroy() { 
}


  ngOnInit()
  {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }
}

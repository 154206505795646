import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie";
import { Observable, throwError } from "rxjs";
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, take, filter, catchError } from 'rxjs/operators';
import { AppStateService } from "../services/app-state.service";
import { AuthService } from "../services/auth/authService";
import { RootService } from "../services/root.service";
import { CommonService } from '../services/commonService'
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class MyHttpInterceptor implements HttpInterceptor {
  isLoggedIn: boolean = false;
  private rq: any = false;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);
  private FreeTokenInProgress = false;
  private FreeTokenSubject: Subject<any> = new BehaviorSubject<any>(null);
  constructor(public router: Router, public rootService: RootService,
    public appStateService: AppStateService,private cookieService: CookieService,
    private authService: AuthService,private toastrService: ToastrService) { }
  interceptOld(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('refresh') !== -1) {
      return next.handle(request);
    }
    const accessExpired = this.cookieService.getObject("access_token");
    const refreshExpired = this.cookieService.get("ref_token") ? this.cookieService.get("ref_token") : localStorage.getItem('ref_token');
    if (accessExpired && refreshExpired) {
      return next.handle(this.injectToken(request));
    }
    if (!accessExpired && !refreshExpired) {
      if (!this.FreeTokenInProgress) {
        this.FreeTokenInProgress = true;
        this.FreeTokenSubject.next(null);
      } else {
        if (this.isLoggedIn) {
          if (!this.refreshTokenInProgress) {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);
            if (!accessExpired) {
              if (localStorage.getItem('refreshTokenInProgress') != 'true') {
                localStorage.setItem('refreshTokenInProgress', 'true');
                return this.rootService.refreshToken2().pipe(
                  switchMap((authResponse) => {
                    if (authResponse) {
                      this.refreshTokenInProgress = false;
                    }
                    this.refreshTokenSubject.next(authResponse.data.refresh_token);
                    return next.handle(this.injectToken(request));
                  }),
                );
              }
            }
            else {
            }
          }
          else {
            if (!accessExpired) {
              if (localStorage.getItem('refreshTokenInProgress') != 'true') {
                localStorage.setItem('refreshTokenInProgress', 'true');
                return this.rootService.refreshToken2();
              }
            }
          }
        }
        else {
          window.location.reload();
        }
      }
    }
    if (!accessExpired && refreshExpired) {
      if (!this.refreshTokenInProgress) {
        this.refreshTokenInProgress = true;
        this.refreshTokenSubject.next(null);
        if (!accessExpired) {
          if (localStorage.getItem('refreshTokenInProgress') != 'true') {
            localStorage.setItem('refreshTokenInProgress', 'true');
            return this.rootService.refreshToken2().pipe(
              switchMap((authResponse) => {
                if (authResponse) {
                  this.refreshTokenInProgress = false;
                }
                this.refreshTokenSubject.next(authResponse.data.refresh_token);
                return next.handle(this.injectToken(request));
              }),
            );
          }
        }
        else {
        }
      } else {
        return this.refreshTokenSubject.pipe(
          filter(result => result !== null),
          take(1),
          switchMap((res) => {
            return next.handle(this.injectToken(request))
          })
        );
      }
    }
    if (!accessExpired) {
      return next.handle(this.injectToken(request));
    }
  }

  private registration = `${environment.apiUrl}api/v1/registration/`;
  private UploadKYCDocURL = `${environment.apiUrl}api/v1/upload_documents/`;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this.rq = req.url.includes(this.registration);


    if (req.url.includes(this.registration)) {
      this.rq = req.url.includes(this.registration);
    }
    else {
      this.rq = req.url.includes(this.UploadKYCDocURL);
    }


    const headers = new HttpHeaders(
      {
        Authorization: `Bearer ${this.authService.getUserActualToken()}`,
      })
    const headers1 = new HttpHeaders(
      {
        Authorization: `Bearer ${this.authService.getUserActualToken()}`,
        'Content-Type': `${this.rq ? "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW" : 'application/json'}`,
      }
    )
    var clone
    if (this.rq) {
      clone = req.clone({
        headers: headers
      })
    }
    else {
      clone = req.clone({
        headers: headers1
      })
    }

    let self = this;
    if (!window.navigator.onLine) {
      this.toastrService.error("Internet is required.");
      return throwError('Internet is required.');
    } else {
      return next.handle(clone).pipe(
        catchError(error => {
         
          if (error.status === 401) {
            this.rootService.refreshTokennew(this.router.url);
            throw error;
          }
          else {
            throw error;
          }
        })
      );
    }
  }
  injectToken(request: HttpRequest<any>) {
    const token = this.cookieService.getObject("access_token");
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}


@Injectable({
  providedIn: 'root'
})
export class FileAppinterceptorService implements HttpInterceptor {
  
  constructor(private cookieService: CookieService, private authService: AuthService, private Comm: CommonService,) { }
  handlError(error: HttpErrorResponse) {
    return throwError(error)
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    const headers = new HttpHeaders(
      {
        Authorization: `Bearer ${this.authService.getUserActualToken()}`,
        'Content-Type': `boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW" : 'application/json'}`,
      }
     
    )
    const clone = req.clone({
      headers: headers
    })
    return next.handle(clone).pipe(
      catchError(error => {
        
        if (error.status === 401) {
          this.Comm.UserLogout();
          throw error;
        }
        else {
          throw error;
        }
      })
    );
  }
}